import {mapActions, mapGetters} from 'vuex'
import { doQuery, doAction } from "@/api/core"
import util from '@/utils/cmyUtils.js'
import PracticeResultDrawer from '@/components/PracticeResultDrawer/PracticeResultDrawer.vue'

export default {
    name: "brushQuestion",
    data() {
      return {
          readonly: false,
          mode: 'smart',
          session_id: undefined,
          brushQuestionLog: [],
          quesNow: {
              ques: undefined,
              optionCount: 4,
              myAnswer: [],
              type: null,
              blank: 0
          },
          addWrongQuestionReplyIndex: undefined,
          wrongTags: [],
          wrongTagSelected: '',
          content: '',

          canNext: true,
          canSubmit: true,

          answering: false,

          singleVal: undefined,
          multiVal:[],

          addWrongQuestionModal: false,
          wrongQuestionComments: '',
          wrongQuestionTag: '',

          practice_result_mode: undefined,
      }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo'
        ])
    },
    components: {
        PracticeResultDrawer
    },
    mounted() {
        if(this.$route.query && this.$route.query.session_id) {
            this.session_id = this.$route.query.session_id
            this.readonly = true
        }
        if (!this.readonly) {
            this.start()
            this.queryWrongTags()
        } else {
            this.getBrushQuestionlog()
        }
    },
    methods: {

        changeMode(mode) {
            const that = this

            if(mode == that.mode) {
                return
            }

            //如果当前是关闭状态
            if(that.readonly){

                //切换模式
                if (that.mode == 'smart')
                    that.mode = 'wrong'
                else
                    that.mode = 'smart'
                return;
            }

            //确认
            that.$confirm({
                centered: true,
                title: '提示',
                content: '切换训练模式需要关闭当前训练后重新开始，是否确定继续？',
                okText: '关闭训练',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    that.close().then((list) => {
                        //切换模式
                        if (that.mode == 'smart')
                            that.mode = 'wrong'
                        else
                            that.mode = 'smart'

                        //清空问题
                        that.brushQuestionLog = []
                        that.quesNow = {
                            ques: undefined,
                            optionCount: 4,
                            myAnswer: [],
                            type: null,
                            blank: 0
                        }
                        //重新启动新的
                        that.start()
                    })
                },
                onCancel() {}
            })
        },

        resetAnswerValue() {
            const that = this
            that.singleVal = undefined
            that.multiVal = []
        },
        nextQuestion() {
            const that = this
            if (!that.canNext) {
                return
            }
            that.canNext = false
            setTimeout(() => {
                that.canNext = true
            }, 1000)
            that.answering = true
            that.getQuestion()
        },
        selectAnswer(answer) {
            const that = this
            if (that.quesNow.type == that.$consts.biz.QUES_TYPES.SINGLE || that.quesNow.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                that.quesNow.myAnswer = [answer]
            } else if (that.quesNow.type == that.$consts.biz.QUES_TYPES.MULTI) {
                var temp = [...that.quesNow.myAnswer]
                if (temp.indexOf(answer) > -1) {
                    temp.splice(temp.indexOf(answer), 1)
                    that.quesNow.myAnswer = temp
                } else {
                    that.quesNow.myAnswer = [...temp, answer]
                }
            }

            //单选题自动提交
            if (that.quesNow && that.quesNow.type == '0')
                that.submitAnswer()
        },
        submitSingleValue() {
          const that = this
            that.quesNow.myAnswer = [that.singleVal]
            that.submitAnswer()
        },
        submitMultiValue() {
            const that = this
            that.quesNow.myAnswer = that.multiVal
            that.submitAnswer()
        },

        submitAnswer() {
            const that = this

            if (that.quesNow.myAnswer.length == 0) {
                return
            }

            if (!that.canSubmit) {
                return
            }
            that.canSubmit = false
            setTimeout(() => {
                that.canSubmit = true
            }, 1000)

            var answerText = ''
            var answerSubmit = ''
            var data = []
            if (that.quesNow.type == '0' || that.quesNow.type == '1' || that.quesNow.type == '4') {
                data = that.quesNow.myAnswer.sort()
            } else if (that.quesNow.type == '2' || that.quesNow.type == '3') {
                data = that.quesNow.myAnswer
            }
            data.forEach((item, index) => {
                if (that.quesNow.type == '0' || that.quesNow.type == '1' || that.quesNow.type == '4') {
                    answerText = answerText.concat(that.$consts.biz.ANSWER_DICTIONARY[item])
                } else if (that.quesNow.type == '2' || that.quesNow.type == '3') {
                    answerText = answerText.concat(item)
                }

                answerSubmit = answerSubmit.concat(item)
                if (index < that.quesNow.myAnswer.length - 1) {
                    answerText = answerText.concat('，')
                    answerSubmit = answerSubmit.concat('|')
                }
            })
            var reply = ''
            if (that.quesNow.type == '0' || that.quesNow.type == '1' || that.quesNow.type == '4') {
                reply = '我选择：' + answerText
            } else if (that.quesNow.type == '2' || that.quesNow.type == '3') {
                reply = answerText
            }
            that.brushQuestionLog = [...that.brushQuestionLog, {
                action: 'query',
                msg: reply
            }]

            doQuery({
                query_code: 'CCAI20',
                param_str2: that.session_id,
                param_str3: that.quesNow.ques.question_id || that.quesNow.ques.action_text,
                param_str4: answerSubmit,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    that.brushQuestionLog = [...that.brushQuestionLog, {
                        action: 'reply',
                        action_text: res.data[0].reply,
                        has_addwrong: 0,
                        ...res.data[0]
                    }]
                    that.answering = false

                    setTimeout(()=>{
                        var ele = document.getElementById('chat-body')
                        ele.scrollTop = ele.scrollHeight
                    },200)
                }
            })
        },
        convertAnswer(num) {
          const that = this
            return util.convertAnswer(num)
        },
        convertAnswer2(num) {
          const that = this
          return util.convertAnswer2(num)
        },
        showComments(index) {
            const that = this
            var obj = {
                ...that.brushQuestionLog[index]
            }
            obj.showComments = true
            that.$set(that.brushQuestionLog, index, obj)
        },
        addWrongQuestion(index) {
            const that = this
            if (that.brushQuestionLog[index].has_addwrong == 0) {
                that.addWrongQuestionReplyIndex = index
                that.wrongQuestionComments = ''
                that.wrongQuestionTag = ''
                that.addWrongQuestionModal = true
            }
        },

        doAddWrongQuestion() {
            const that = this
            if(!that.wrongQuestionTag) {
                that.$message.error('请至少选择一个标签')
                return
            }
            var ques = {
                ...that.brushQuestionLog[that.addWrongQuestionReplyIndex - 2]
            }
            var reply = {
                ...that.brushQuestionLog[that.addWrongQuestionReplyIndex]
            }
            var question_id = ques.action_text || ques.question_id
            doAction({
                action_code: 'LNW0',
                param_str2: question_id,
                param_str3: '',
                param_str4: that.wrongQuestionTag,
                param_str5: (that.wrongQuestionComments?that.wrongQuestionComments:'')
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success('成功加入错题集')
                        reply.has_addwrong = 1
                        that.$set(that.brushQuestionLog, that.addWrongQuestionReplyIndex, reply)
                        that.addWrongQuestionModal = false
                    }else {
                        that.$message.error(res.data.errmsg)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },

        start() {
            const that = this
            that.$message.loading('正在启动AI刷题任务')
            doQuery({
                query_code: 'CCAI00',
                param_str3: that.mode,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    var { session_id, session_type } = res.data[0]
                    that.session_id = session_id
                    if (session_type == 'old') {
                        that.$confirm({
                            centered: true,
                            title: '温馨提示',
                            content: '检测到您有未结束的刷题训练，是否继续该训练？',
                            okText: '继续训练',
                            okType: 'primary',
                            cancelText: '新训练',
                            onOk() {
                                //如果有旧的，设定为旧的刷题模式
                                that.mode = res.data[0].training_mode
                                //获取刷题的数据
                                that.getBrushQuestionlog()
                                    .then((boGetQuestion) => {
                                        if (boGetQuestion) {
                                            that.getQuestion()
                                        }
                                    })
                            },
                            onCancel() {
                                that.close()
                                    .then((list) => {
                                        that.start()
                                    })
                            }
                        })
                    }
                    if (session_type == 'new') {
                        that.getQuestion()
                    }
                }else {
                    that.$message.error('抱歉，本次启动任务失败')
                    that.$router.go(-1)
                }
            })
        },
        closeChat() {
            const that = this
            that.$confirm({
                centered: true,
                title: '提示',
                content: '是否确认结束本次训练？',
                okText: '确认',
                okType: 'primary',
                cancelText: '取消',
                onOk() {
                    that.close()
                        .then((list) => {
                            that.readonly = true
                            that.practice_result_mode = '0'
                        })
                },
                onCancel() {}
            })
        },
        close() {
            const that = this
            return new Promise((resolve,reject) => {
                doQuery({
                    query_code: 'CCAI40',
                    param_str1: '1',
                    param_str2: that.session_id,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK) {
                        resolve()
                    }
                })
            })
        },
        queryWrongTags(){
            const that = this
            doQuery({
                query_code: 'LN010',
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK) {
                    that.wrongTags = res.data
                }
            })
        },
        getQuestion() {
            const that = this
            that.resetAnswerValue()
            that.quesNow = {
                ques: undefined,
                optionCount: 4,
                myAnswer: [],
                type: null,
                blank: 0
            }

            doQuery({
                query_code: 'CCAI10',
                param_str2: that.session_id,
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    var ques = res.data[0]
                    //没有查询到问题
                    if (ques.ai_type == 'x') {
                        //1. 如果此时还没有问题日志，说明是首次获取问题，则直接关闭
                        if (that.mode == 'wrong' && (!that.brushQuestionLog || that.brushQuestionLog.length == 0)) {

                            that.$confirm({
                                centered: true,
                                title: '温馨提示',
                                content: '没有查询到您的错题历史，将自动切换智能模式',
                                okText: '确定',
                                okType: 'primary',
                                cancelText: '取消',
                                onOk() {
                                    that.close().then((list) => {
                                        that.mode = 'smart';
                                        //清空问题
                                        that.brushQuestionLog = []
                                        that.quesNow = {
                                            ques: undefined,
                                            optionCount: 4,
                                            myAnswer: [],
                                            type: null,
                                            blank: 0
                                        }
                                        //重新启动新的
                                        that.start()
                                    })
                                },
                                onCancel() {}
                            })

                        } else {
                            that.$message.info('没有查询到更多的问题')
                        }
                    } else {
                        ques.action_text = ques.question_id
                        that.brushQuestionLog = [...that.brushQuestionLog, {
                            action: 'reply',
                            ...ques
                        }]

                        var optionCount = 0
                        Object.keys(ques).forEach(function(key) {
                            if (key.startsWith('answer') && ques[key]) {
                                optionCount++
                            }
                        })

                        that.quesNow.ques = ques
                        that.quesNow.optionCount = optionCount
                        that.quesNow.type = ques.type
                        that.$set(that.quesNow, 'blank', ques.type == '2' ? (util.getStrCount(ques.question,'()') + util.getStrCount(ques.question, '（）')) : 0)
                        var answer_slot = []
                        if (ques.type == '2') {
                            for (var i = 0; i < that.quesNow.blank; i++) {
                                answer_slot.push('')
                            }
                        }
                        that.quesNow.myAnswer = answer_slot
                        that.answering = true

                        setTimeout(()=>{
                            var ele = document.getElementById('chat-body')
                            ele.scrollTop = ele.scrollHeight
                        },200)
                    }
                }
            })
        },
        getBrushQuestionlog() {
            const that = this

            return new Promise((resolve, reject) => {
                doQuery({
                    query_code: 'CCAI30',
                    param_str2: that.session_id,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK) {
                        if (res.data && res.data.length > 0) {
                            var arr = []
                            res.data.forEach((item, index) => {
                                if (item.action == 'query') {
                                    var answers = item.action_text.split('|')
                                    var answerText = ''
                                    answers.forEach((item1, index1) => {
                                        if (res.data[index - 1].type == '0' || res.data[index - 1].type == '1'|| res.data[index - 1].type == '4') {
                                            answerText = answerText.concat(that.$consts.biz.ANSWER_DICTIONARY[item1])
                                        } else if (res.data[index - 1].type == '2' || res.data[index - 1].type == '3') {
                                            answerText = answerText.concat(item1)
                                        }
                                        if (index1 < answers.length - 1) {
                                            answerText = answerText.concat('，')
                                        }
                                    })
                                    var reply = ''
                                    if (res.data[index - 1].type == '0' || res.data[index - 1].type == '1' || res.data[index - 1].type == '4') {
                                        reply = '我选择：' + answerText
                                    } else if (res.data[index - 1].type == '2' || res.data[index - 1].type == '3') {
                                        reply = answerText
                                    }
                                    item.msg = reply
                                }
                                item.blank = item.type == '2' ? (util.getStrCount(item.question,'()') + util.getStrCount(item.question, '（）')) : 0
                                arr.push(item)
                            })
                            that.brushQuestionLog = arr
                            // 如果上一个题目没有答，直接让作答
                            var last = res.data[res.data.length - 1]
                            if (last.action == 'reply' && last.question) {
                                var optionCount = 0
                                Object.keys(last).forEach(function(key) {
                                    if (key.startsWith('answer') && last[key]) {
                                        optionCount++
                                    }
                                })
                                that.quesNow.ques = last
                                that.quesNow.optionCount = optionCount
                                that.quesNow.type = last.type || that.$consts.biz.QUES_TYPES.SINGLE
                                that.quesNow.blank = last.type == '2' ? (util.getStrCount(last.question,'()') + util.getStrCount(last.question, '（）')) : 0
                                var answer_slot = []
                                if (last.type == '2') {
                                    for (var i = 0; i < that.quesNow.blank; i++) {
                                        answer_slot.push('')
                                    }
                                }
                                that.quesNow.myAnswer = answer_slot

                                if (!that.readonly) {
                                    that.answering = true
                                }

                                setTimeout(()=>{
                                    var ele = document.getElementById('chat-body')
                                    ele.scrollTop = ele.scrollHeight
                                },200)

                                resolve(false)
                            }
                        }else {
                            that.brushQuestionLog = []
                            resolve(true)
                        }
                    }
                })
            })
        },
        closePracticeResult() {
            const that = this
            that.practice_result_mode = undefined
        },
    }
}
